import React, { ReactNode } from "react";
import { Layout } from "./src/components/Layout";
import "./src/styles/global.css";

interface Props {
  element: ReactNode;
  props: object;
}

export const wrapPageElement: React.FC<Props> = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
