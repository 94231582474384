import React, { PropsWithChildren, useEffect } from "react";

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (typeof window === "undefined") return;
    window.scrollTo(0, 0);
  }, []);

  return <div className="relative w-full h-auto">{children}</div>;
};
